import {
  BaseQueryFn,
  createApi,
  defaultSerializeQueryArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  RootState,
  dynamicMiddlewaresInstance,
  registerReducer,
} from "@core/store"
import { baseQueryWithReauth } from "@/modules/app/store"
import { ADV } from "../interfaces"
import { stringifyWithBigInt } from "@/core/utils"

const tagTypes = [
  "Orders",
  "Order",
  "Agents",
  "AgentsFull",
  "Agent",
  "Types",
  "Formats",
  "Statuses",
  "Campaigns",
  "RefCodes",
  "Bots",
  "OrdersFilters",
  "AgentsFilters",
  "TopUp",
  "TopUpSource",
  "Balances",
  "Locations",
  "Payments",
  "PaymentsFilters",
] as const

const reducerPath: string = "advApi"
export const baseAdvApiURL = new URL(
  import.meta.env.REACT_APP_ADV_API,
  window.location.origin,
).toString()
export const baseUrl = `${
  baseAdvApiURL.endsWith("/") ? baseAdvApiURL : baseAdvApiURL + "/"
}`
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
  jsonReplacer: stringifyWithBigInt
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const endpoints = (build: ApiEndpointBuider) => ({
  agentsList: build.query<ADV.AgentsListRes, ADV.AgentsListReq>({
    query: () => ({
      url: `agents/list`,
      method: "GET",
    }),
    providesTags: ["Agents"],
  }),
  agentsFullList: build.query<ADV.AgentsFullListRes, ADV.AgentsFullListReq>({
    query: ({ queryParams }) => ({
      url: `agents/all_data_list`,
      method: "GET",
      params: Object.entries(queryParams)
        .filter(([key, value]) =>
          Array.isArray(value)
            ? !!value.length
            : value !== null && value !== undefined,
        )
        .map(([key, value]) =>
          Array.isArray(value)
            ? value.map((v) => `${key}=${v}`).join("&")
            : `${key}=${value}`,
        )
        .join("&"),
    }),
    providesTags: ["AgentsFull"],
  }),
  createAgent: build.mutation<ADV.CreateAgentRes, ADV.CreateAgentReq>({
    query: ({ body }) => {
      return {
        url: `agent`,
        method: "POST",
        body,
      }
    },
    invalidatesTags: ["Agents"],
  }),
  getAgent: build.query<ADV.GetAgentRes, ADV.GetAgentReq>({
    query: ({ pathParams: { id } }) => ({
      url: `agents/${id}`,
      method: "GET",
    }),
    providesTags: ["Agent"],
  }),
  updateAgent: build.mutation<ADV.UpdateAgentRes, ADV.UpdateAgentReq>({
    query: ({ body, pathParams: { id } }) => {
      return {
        url: `agent/${id}`,
        method: "PATCH",
        body,
      }
    },
    invalidatesTags: ["Agents", "Agent"],
  }),
  createCampaign: build.mutation<ADV.CreateCampaignRes, ADV.CreateCampaignReq>({
    query: ({ body }) => {
      return {
        url: `campaigns`,
        method: "POST",
        body,
      }
    },
    invalidatesTags: ["Campaigns"],
  }),
  ordersList: build.query<ADV.OrdersListRes, ADV.OrdersListReq>({
    query: ({ queryParams }) => ({
      url: `orders/list`,
      method: "GET",
      params: Object.entries(queryParams)
        .filter(([key, value]) =>
          Array.isArray(value)
            ? !!value.length
            : value !== null && value !== undefined,
        )
        .map(([key, value]) =>
          Array.isArray(value)
            ? value.map((v) => `${key}=${v}`).join("&")
            : `${key}=${value}`,
        )
        .join("&"),
    }),
    providesTags: ["Orders"],
  }),
  getOrder: build.query<ADV.GetOrderRes, ADV.GetOrderReq>({
    query: ({ pathParams: { id } }) => ({
      url: `orders/${id}`,
      method: "GET",
    }),
    providesTags: ["Order"],
  }),
  createOrder: build.mutation<ADV.CreateOrderRes, ADV.CreateOrderReq>({
    query: ({ body }) => {
      return {
        url: `orders`,
        method: "POST",
        body,
      }
    },
    invalidatesTags: ["Orders"],
  }),
  updateOrder: build.mutation<ADV.UpdateOrderRes, ADV.UpdateOrderReq>({
    query: ({ body, pathParams: { id } }) => {
      return {
        url: `orders/${id}`,
        method: "PATCH",
        body,
      }
    },
    invalidatesTags: ["Orders", "Order"],
  }),
  typesList: build.query<ADV.TypesListRes, ADV.TypesListReq>({
    query: ({ queryParams }) => ({
      url: `types/list`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["Types"],
  }),
  formatsList: build.query<ADV.FormatsListRes, ADV.FormatsListReq>({
    query: () => ({
      url: `formats`,
      method: "GET",
    }),
    providesTags: ["Formats"],
  }),
  statusesList: build.query<ADV.StatusesListRes, ADV.StatusesListReq>({
    query: ({ queryParams }) => ({
      url: `statuses/list`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["Statuses"],
  }),
  campaignsList: build.query<ADV.CampaignsListRes, ADV.CampaignsListReq>({
    query: () => ({
      url: `campaigns/list`,
      method: "GET",
    }),
    providesTags: ["Campaigns"],
  }),
  refCodesList: build.query<ADV.RefCodesListRes, ADV.RefCodesListReq>({
    query: () => ({
      url: `free/ref_codes`,
      method: "GET",
    }),
    providesTags: ["RefCodes"],
  }),
  botsList: build.query<ADV.BotsListRes, ADV.BotsListReq>({
    query: ({ queryParams }) => ({
      url: `free/bots`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["Bots"],
  }),
  orderFiltersList: build.query<ADV.FiltersListRes, ADV.FiltersListReq>({
    query: () => ({
      url: `orders/filters`,
      method: "GET",
    }),
    providesTags: ["OrdersFilters"],
  }),
  agentFiltersList: build.query<ADV.FiltersListRes, ADV.FiltersListReq>({
    query: () => ({
      url: `agents/filters`,
      method: "GET",
    }),
    providesTags: ["AgentsFilters"],
  }),
  topUpList: build.query<ADV.TopUpListRes, ADV.TopUpListReq>({
    query: () => ({
      url: `top_up/list`,
      method: "GET",
    }),
    providesTags: ["TopUp"],
  }),
  createTopUp: build.mutation<ADV.CreateTopUpRes, ADV.CreateTopUpReq>({
    query: ({ body }) => {
      return {
        url: `top_up`,
        method: "POST",
        body,
      }
    },
    invalidatesTags: ["TopUp"],
  }),
  topUpSourceList: build.query<ADV.TopUpSourceListRes, ADV.TopUpSourceListReq>({
    query: () => ({
      url: `top_ups_from/list`,
      method: "GET",
    }),
    providesTags: ["TopUpSource"],
  }),
  balancesList: build.query<ADV.BalancesListRes, ADV.BalancesListReq>({
    query: () => ({
      url: `balances/list`,
      method: "GET",
    }),
    providesTags: ["Balances"],
  }),
  locationsList: build.query<ADV.LocationsListRes, ADV.LocationsListReq>({
    query: () => ({
      url: `locations/list`,
      method: "GET",
    }),
    providesTags: ["Locations"],
  }),
  paymentsList: build.query<ADV.PaymentsListRes, ADV.PaymentsListReq>({
    query: ({ queryParams }) => ({
      url: `payments/list`,
      method: "GET",
      params: Object.entries(queryParams)
        .filter(([key, value]) =>
          Array.isArray(value)
            ? !!value.length
            : value !== null && value !== undefined,
        )
        .map(([key, value]) =>
          Array.isArray(value)
            ? value.map((v) => `${key}=${v}`).join("&")
            : `${key}=${value}`,
        )
        .join("&"),
    }),
    providesTags: ["Payments"],
  }),
  paymentFiltersList: build.query<ADV.FiltersListRes, ADV.FiltersListReq>({
    query: () => ({
      url: `payments/filters`,
      method: "GET",
    }),
    providesTags: ["PaymentsFilters"],
  }),
})

export const advApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery: (...args) => baseQueryWithReauth(baseQuery, args),
  endpoints: (build) => ({
    ...endpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(advApi.middleware)
registerReducer(advApi.reducerPath, advApi.reducer)

export const {
  useOrdersListQuery,
  useGetOrderQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useAgentsListQuery,
  useAgentsFullListQuery,
  useGetAgentQuery,
  useCreateAgentMutation,
  useUpdateAgentMutation,
  useTypesListQuery,
  useStatusesListQuery,
  useCampaignsListQuery,
  useCreateCampaignMutation,
  useRefCodesListQuery,
  useBotsListQuery,
  useOrderFiltersListQuery,
  useAgentFiltersListQuery,
  useTopUpListQuery,
  useCreateTopUpMutation,
  useTopUpSourceListQuery,
  useBalancesListQuery,
  useLocationsListQuery,
  usePaymentFiltersListQuery,
  usePaymentsListQuery,
  useFormatsListQuery,
} = advApi
