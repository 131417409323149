import { Alert, Box, Grid, IconButton, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, {FC, useState} from 'react';
import { Edit as EditIcon, Done as DoneIcon, Delete as DeleteIcon, Add as AddIcon, Forward } from '@mui/icons-material';
import { useBotsGroupListQuery, useDeleteBotsGroupMutation } from '../../services/bots/botsGroupApi';
import CreateNewBotsGroup from './createNewBotsGroup';
import { useLocation, useNavigate } from 'react-router-dom';

interface IBotsTableProps {
    
}

interface Column {
    id: 'id' | 'name' | 'note' | 'referral_code';
    label: string;
    minWidth?: number;
    align?: 'right' | 'left' | 'center';
    format?: (...value: any) => React.ReactNode;
    onEdit?: (...value: any) => React.ReactNode,
}

const columns: readonly Column[] = [
    // { id: 'id', label: 'ID', minWidth: 50 },
    {
        id: 'name',
        label: 'Название',
        minWidth: 270,
    },
    {
        id: 'note',
        label: 'Заметка',
        minWidth: 270,
    },
    {
        id: 'referral_code',
        label: 'Название',
        minWidth: 270,
    },
];

const BotsGroupTable: FC<IBotsTableProps> = (props) => {
    const {isLoading, isError, data} = useBotsGroupListQuery(null, {
        refetchOnFocus: true
    });
    const [deleteBot] = useDeleteBotsGroupMutation();
    const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
    const [editing, setEditing] = useState(false);
    const location = useLocation();

    const handleToggleEdit = () => {
        setEditing(!editing);
    };

    return (<>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant='h4' m={2}>Группы ботов</Typography>
            {isLoading && <LinearProgress />}
            {isError && <Alert severity="error">Произошла ошибка!</Alert>}
            {!isError && !isLoading && <>
                <TableContainer sx={{ maxHeight: '75vh' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell
                                    key={'open'}
                                    align={'center'}
                                >
                                    Открыть
                                </TableCell>
                                {editing && <TableCell key={'delete'} align={'center'}>
                                    Удалить
                                </TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!data?.length && data.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            const val = column.format ? column.format(value) : value?.toString();
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {editing && !!column.onEdit
                                                        ? column.onEdit({value: val, proxyId: row.id})
                                                        : val
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell key={`open_${row.id}`} align={'center'}>
                                            <IconButton color="primary" aria-label="open" href={`${location.pathname}/${row.id}`}>
                                                <Forward/>
                                            </IconButton>
                                        </TableCell>
                                        {editing && <TableCell key={`delete_${row.id}`} align={'center'}>
                                            <IconButton color="primary" aria-label="delete" onClick={() => deleteBot(row.id)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </TableCell>}
                                    </TableRow>
                                );
                            })}
                            {createNewIsOpen && <CreateNewBotsGroup/>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    {!createNewIsOpen && <Box m={2}>
                        <IconButton color="primary" aria-label="edit" onClick={() => setCreateNewIsOpen(true)}>
                            <AddIcon/>
                        </IconButton>
                    </Box>}
                    <Box m={2}>
                        <IconButton color="primary" aria-label="add-proxy" onClick={handleToggleEdit}>
                            {!!editing ? <DoneIcon /> : <EditIcon />}
                        </IconButton>
                    </Box>
                </Grid>
            </>}
        </Paper>
    </>);
};

export default BotsGroupTable;
