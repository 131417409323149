import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import React, {FC} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PostForm from '../components/posts/editPost';

interface IPostPageProps {
    
}

const PostPage: FC<IPostPageProps> = (props) => {
    const { channelId, postId } = useParams();
    const navigate = useNavigate();

    if (!channelId || !postId) {
        navigate(-1);
        return null;
    }

    const onClose = () => {
        navigate(-1);
    }

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>
                Редактирование поста {!isNaN(+postId) && `id:${postId}`}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <PostForm channelId={+channelId} id={isNaN(+postId) ? undefined : +postId} />
            </DialogContent>
        </Dialog>
    );
};

export default PostPage;