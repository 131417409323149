import { Add, Cancel, Check, Delete, Edit, Remove } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Link, Stack, TextField, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { IPost } from '../../../../interface/interface';
import EditUrlButton from './editUrlButton';
import UrlButton from './urlButton';

export interface IUrlButtonData {
    text: string;
    url: string;
}
interface IUrlButtonState {
    data: IUrlButtonData;
    edit: boolean;
}
interface IUrlButtonProps {
    data?: IPost['reply_markup'];
    onChange: (data: IPost['reply_markup']) => void;
}

const SEPARATOR = '_';
const initialButtonData: IUrlButtonState = {
    data: {
        text: '',
        url: 'https://'
    },
    edit: true,
}

const convertPropsDataToState = (propsData: IUrlButtonProps['data']): IUrlButtonState[][] => 
    propsData?.inline_keyboard.map(row => row.map(data => ({data, edit: false}))) ?? [];


const UrlButtons: FC<IUrlButtonProps> = (props) => {
    const { onChange } = props;
    const [data, setData] = useState<IUrlButtonState[][]>(convertPropsDataToState(props.data));

    const removeButton = (row: number, col: number) => {
        if (!data?.length) {
            return;
        }
        const newRow = [...data[row]];
        newRow.splice(col, 1);
        const newData = [...data];
        !!newRow.length ? newData.splice(row, 1, newRow) : newData.splice(row, 1);
        setData([...newData]);
        onChange({inline_keyboard: newData.map(row => row.map(({data}) => data))});
    }
    const addButton = (rowIndex: number) => {
        const newData = [...data ?? []];
        // существует ли такая строчка?
        if (rowIndex < 0 || rowIndex > (newData?.length ?? 0) + 1) {
            return;
        }
        if (newData.length === rowIndex) {
            newData.push([]);
        }
        const newRow = [...newData[rowIndex]];
        newRow.push(initialButtonData);
        newData.splice(rowIndex, 1, newRow);
        setData(newData);
    }
    const updateButton = (rowIndex: number, colIndex: number, btnData: IUrlButtonData) => {
        const newData = [...data ?? []];
        // существует ли такая строчка?
        if (rowIndex < 0 || rowIndex > (newData?.length ?? 0) + 1) {
            return;
        }
        if (newData.length === rowIndex) {
            newData.push([]);
        }
        const newRow = [...newData[rowIndex]];
        newRow[colIndex] = {data: btnData, edit: false};
        newData.splice(rowIndex, 1, newRow);
        setData(newData);
        onChange({inline_keyboard: newData.map(row => row.map(({data}) => data))});
    }
    const setEdit = (rowIndex: number, colIndex: number) => {
        const newData = [...data ?? []];
        // существует ли такая строчка?
        if (rowIndex < 0 || rowIndex > (newData?.length ?? 0) + 1) {
            return;
        }
        if (newData.length === rowIndex) {
            newData.push([]);
        }
        const newRow = [...newData[rowIndex]];
        newRow[colIndex] = {data: {...newRow[colIndex].data}, edit: true};
        newData.splice(rowIndex, 1, newRow);
        setData(newData);
    }

    return <>
        <Typography variant='body1'>Кнопки:</Typography>
        <Stack direction={'column'} spacing={1} >
            {!!data?.length && data.map((row, iRow) => 
                <Stack direction={'row'} spacing={1} key={`row_${iRow}`} >
                    {row.map((btnSate, iCol) => <Box sx={{width: '100%'}} key={`btn_${iRow}${SEPARATOR}${iCol}`}>
                        {btnSate.edit
                            ? <EditUrlButton data={btnSate.data} onRemove={() => removeButton(iRow, iCol)} onSave={(data) => updateButton(iRow, iCol, data)} />
                            : <Stack direction='row' sx={{width: '100%'}} key={`${iRow}${SEPARATOR}${iCol}`} >
                                <UrlButton data={btnSate.data} />
                                <Button onClick={() => setEdit(iRow, iCol)} ><Edit/></Button>
                            </Stack>
                        }
                    </Box>)}
                    <Button onClick={() => addButton(iRow)} ><Add/></Button>
                </Stack>
            )}
            <Button onClick={() => addButton(data.length)} key={`btn_add_row`}><Add/></Button>
        </Stack>
    </>
}

export default UrlButtons;
