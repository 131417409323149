import { Alert, Grid, LinearProgress } from '@mui/material';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePostsQuery } from '../../services/posts/postsApi';
import AddNew from './addNew';
import PostCard from './postCard';

interface IPostsListProps {
    channelId: number;
}

const PostsList: FC<IPostsListProps> = (props) => {
    const {isLoading, isError, data} = usePostsQuery(props.channelId, {
        refetchOnFocus: true
    })
    const navigate = useNavigate();
    let location = useLocation();

    return (<>
        {isError && <Alert severity="error">Во время загрузки списка постов произошла ошибка!</Alert>}
        {isLoading && <LinearProgress />}
        <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={6} xl={4} >
                <AddNew onClick={() => navigate(
                    'new', 
                    {
                        state: {
                            backgroundLocation: location,
                        }
                    }
                )}/>
            </Grid>
            {!data?.length && !isError && !isLoading && <Grid item xs={12} md={6} xl={4} >
                <Alert 
                    sx={{
                        width: '100%',
                        height: '100%',
                        minHeight: '100px',
                        display: 'flex',
                        padding: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }} 
                    severity='info'
                >
                    Постов нет
                </Alert>
            </Grid>}
            {!!data?.length && data.map(p => <Grid item xs={12} md={6} xl={4} key={`${p.channel.id}_${p.id}`} >
                <PostCard {...p} />
            </Grid>)}
        </Grid>
    </>);
};

export default PostsList;
