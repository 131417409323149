import React, {FC} from 'react';
import BotsGroupTable from '../components/botsGroup/botsGroupsTable';
import { Box } from '@mui/material';

interface IBotsGroupPageProps {
    
}

const BotsGroupPage: FC<IBotsGroupPageProps> = (props) => {
    return (
        <Box width="100%">
            <BotsGroupTable/>
        </Box>
    );
};

export default BotsGroupPage;