import {BaseQueryFn, createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { IBotsGroup, ICreateBot, ICreateBotsGroup, IDeleteBotParams } from '../../interface/interface';
import { dynamicMiddlewaresInstance, registerReducer, RootState } from '@/core/store';
import { baseQueryWithReauth } from '@/modules/app/store';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

const tagTypes = ['BotsGroups', 'BotsGroup'] as const

const reducerPath: string = "botsGroupApi"
export const baseTGStatApiURL = new URL(
  import.meta.env.REACT_APP_POSTBOT_API,
  window.location.origin,
).toString()
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: `${
    baseTGStatApiURL.endsWith("/") ? baseTGStatApiURL : baseTGStatApiURL + "/"
  }`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const endpoints = (build: ApiEndpointBuider) => ({
  getBotsGroup: build.query<IBotsGroup, number>({
    query: (botsGroupId: number) => ({
      url: `/bots-group/${botsGroupId}`,
      params: {},
      method: 'GET'
    }),
    providesTags: ['BotsGroup'],
  }),
  botsGroupList: build.query<IBotsGroup[], null>({
    query: () => ({
      url: `/bots-group`,
      params: {},
      method: 'GET'
    }),
    providesTags: ['BotsGroups'],
  }),
  deleteBotsGroup: build.mutation<boolean, IBotsGroup['id']>({
    query: (id: number) => ({
        url: `/bots-group/${id}`,
        method: 'DELETE',
    }),
    invalidatesTags: ['BotsGroups']
  }),
  createBotsGroup: build.mutation<boolean, ICreateBotsGroup>({
    query: (bot) => ({
        url: `/bots-group`,
        method: 'POST',
        body: bot
    }),
    invalidatesTags: ['BotsGroups']
  }),
  addBotToBotsGroup: build.mutation<boolean, ICreateBot>({
    query: (bot) => {
      const { botsGroupId, ...body } = bot;
      return {
        url: `/bots-group/${bot.botsGroupId}`,
        method: 'POST',
        body,
      }
    },
    invalidatesTags: ['BotsGroup', 'BotsGroups']
  }),
  deleteBot: build.mutation<boolean, IDeleteBotParams>({
    query: ({botId, groupId}) => ({
        url: `/bots-group/${groupId}/${botId}`,
        method: 'DELETE',
    }),
    invalidatesTags: ['BotsGroup']
  }),
})

export const botsGroupApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery: (...args) => baseQueryWithReauth(baseQuery, args),
  endpoints: (build) => ({
    ...endpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(botsGroupApi.middleware)
registerReducer(botsGroupApi.reducerPath, botsGroupApi.reducer)

export const {
  useBotsGroupListQuery,
  useDeleteBotsGroupMutation,
  useCreateBotsGroupMutation,
  useGetBotsGroupQuery,
  useAddBotToBotsGroupMutation,
  useDeleteBotMutation,
} = botsGroupApi;