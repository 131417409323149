import { FieldValues, TextFieldElement, useFormContext } from "react-hook-form-mui"
import { ADV } from "../../interfaces"

type IField = Pick<
  ADV.CreateOrder,
  "invite_link"
>

export function MembersLimit<T extends FieldValues = IField>(): JSX.Element {
  const ctx = useFormContext<IField>()
  const createsJoinRequest = ctx?.watch("invite_link.creates_join_request")

  return (
    <TextFieldElement<IField> name="invite_link.member_limit" label="Лимит вступлений" type="number" disabled={createsJoinRequest} />
  )
}
