import { PersonRemove } from "@mui/icons-material"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from "@mui/material"
import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { IChannel } from "../../interface/interface"

interface IChannelCardProps extends IChannel {}

const ChannelCard: FC<IChannelCardProps> = (props) => {
  const navigate = useNavigate()
  return (
    <>
      <Card>
        <CardHeader
          title={props.title}
          subheader={
            <>
              id:{props.id % 1000000000000}{" "}
              {["kicked", "left", "restricted"].includes(props.status) && (
                <PersonRemove sx={{ verticalAlign: "text-bottom" }} />
              )}
            </>
          }
        />
        {/* <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image="/static/images/cards/contemplative-reptile.jpg"
            /> */}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div"></Typography>
          <Typography variant="body2" color="text.secondary"></Typography>
        </CardContent>
        <CardActions>
          {/* <Link href={`${props.id}`} variant='button'>Подробнее</Link> */}
          <Button
            onClick={() =>
              navigate(`${props.id}`, {
                state: {
                  from: window.location.pathname,
                  title: "К списку каналов",
                },
              })
            }
          >
            Открыть посты
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default ChannelCard
