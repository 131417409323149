import { Info } from "@mui/icons-material"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  CircularProgress,
  Alert,
  SelectChangeEvent,
  Typography,
  Link,
  TextField,
  InputAdornment,
  OutlinedInput,
} from "@mui/material"
import { Stack } from "@mui/system"
import { FC } from "react"
import { useBotsGroupListQuery } from "../../../services/bots/botsGroupApi"

interface IBotsSelectorFieldProps {
  id?: number
  // referal: string;
  onChangeSelectedBotsGroup: (selected: number) => void
  onChangeReferal: (referal: string) => void
}

const BotsGroupSelectorField: FC<IBotsSelectorFieldProps> = (props) => {
  const { isLoading, isError, data } = useBotsGroupListQuery(null)

  const onChange = ({ target: { value } }: SelectChangeEvent<number[]>) => {
    props.onChangeSelectedBotsGroup(+value)
  }

  return (
    <>
      {isLoading && <CircularProgress />}
      {isError && <Alert severity="error">Произошла ошибка!</Alert>}
      {!!data && (
        <>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel id={`bots-select`}>Список ботов</InputLabel>
              <Select
                labelId={`bots-select`}
                id="bots-checkbox"
                // multiple
                value={props.id !== undefined ? [props.id] : props.id}
                onChange={onChange}
                input={<OutlinedInput label="Список ботов" />}
                // renderValue={(selected) => (
                //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                //         {selected.map((value) => {
                //             const bot = data.find(b => b.id === value);
                //             let color: "error" | "default" | "primary" | "secondary" | "info" | "success" | "warning";
                //             switch (bot?.status) {
                //                 case BotStatus.BANNED: color = 'error'; break;
                //                 case BotStatus.IN_USE: color = 'success'; break;
                //                 default: color = 'default';
                //             }
                //             return <Chip key={value} label={`@${bot?.username}`} color={color} />
                //         })}
                //     </Box>
                // )}
                // MenuProps={MenuProps}
              >
                {data?.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    <ListItemText primary={group.name} />
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption">
                <Info
                  color="disabled"
                  fontSize="small"
                  sx={{ verticalAlign: "middle" }}
                />
                Выберите ботов, которые будут подставлены вместо {"{bot}"}. В
                случае блокировки бота, будет подставлена ссылка на следующего.
                Боты будут подставляться в выбранном порядке. Добавить ботов
                можно <Link href="/telegram/post-bot/bots">здесь</Link>.
              </Typography>
            </FormControl>
            {/* <TextField
                    id="referal-input"
                    label="Реферальный код"
                    value={props.referal ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChangeReferal(e.target.value)}
                    required
                    InputProps={{
                        startAdornment: <InputAdornment position="start" sx={{height: '100%'}} >
                            ?start=
                        </InputAdornment>
                    }}
                /> */}
          </Stack>
        </>
      )}
    </>
  )
}

export default BotsGroupSelectorField
