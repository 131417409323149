import { BaseQueryFn, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {
  IAd,
  IBot,
  ICreatePost,
  IPost,
  IUpdatePost,
} from "../../interface/interface"
import { dynamicMiddlewaresInstance, registerReducer, RootState } from "@/core/store"
import { baseQueryWithReauth } from "@/modules/app/store"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"

const tagTypes = ["Posts", "Post", "Bots"] as const

const reducerPath: string = "postApi"
export const baseTGStatApiURL = new URL(
  import.meta.env.REACT_APP_POSTBOT_API,
  window.location.origin,
).toString()
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: `${
    baseTGStatApiURL.endsWith("/") ? baseTGStatApiURL : baseTGStatApiURL + "/"
  }`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const endpoints = (build: ApiEndpointBuider) => ({
  getPost: build.query<IAd, number>({
    query: (postId: number) => ({
      url: `post/${postId}`,
      params: {},
      method: "GET",
    }),
    providesTags: ["Post"],
  }),
  posts: build.query<IAd[], number>({
    query: (channelId: number) => ({
      url: `post`,
      params: { channelId },
      method: "GET",
    }),
    providesTags: ["Posts"],
  }),
  createPost: build.mutation<IAd, ICreatePost>({
    query: (post: ICreatePost) => ({
      url: `post`,
      body: post,
      method: "POST",
    }),
    invalidatesTags: ["Posts", "Post"],
  }),
  updatePost: build.mutation<IAd, Partial<IUpdatePost>>({
    query: (data: IUpdatePost) => ({
      url: `post/${data.id}`,
      body: data,
      method: "PATCH",
    }),
    invalidatesTags: ["Posts", "Post"],
  }),
  deletePost: build.mutation<any, number>({
    query: (id: number) => ({
      url: `post/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: ["Posts", "Post"],
  }),
  sendPreview: build.mutation<boolean, ICreatePost>({
    query: (post: ICreatePost) => ({
      url: `post/preview`,
      body: post,
      method: "POST",
    }),
  }),
})

export const postApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery: (...args) => baseQueryWithReauth(baseQuery, args),
  endpoints: (build) => ({
    ...endpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(postApi.middleware)
registerReducer(postApi.reducerPath, postApi.reducer)

export const {
  usePostsQuery,
  useGetPostQuery,
  useSendPreviewMutation,
  useCreatePostMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
} = postApi
