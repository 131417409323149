import { Grid, IconButton, InputAdornment, TableCell, TableRow, TextField } from '@mui/material';
import React, {FC, useState} from 'react';
import { Done as DoneIcon, AlternateEmail } from '@mui/icons-material';
import { BotStatus, ICreateBot } from '../../interface/interface';
import { useAddBotToBotsGroupMutation } from '../../services/bots/botsGroupApi';

interface ICreateNewBotProps {
    groupId: number;
}

const CreateNewBot: FC<ICreateNewBotProps> = (props) => {
    const emptyNewBot: ICreateBot = {
        username: '',
        status: BotStatus.NEW,
        botsGroupId: props.groupId,
    }
    const [createBot] = useAddBotToBotsGroupMutation();
    const [newBot, setNewBot] = useState({...emptyNewBot});

    const handleEdit: <T extends keyof ICreateBot>(val: {key: T, value: ICreateBot[T]}) => void = (val) => {
        const bot = {...newBot};
        bot[val.key] = val.value;
        
        setNewBot(bot);
    }
    const handleSaveNewBot = async () => {
        await createBot(newBot).unwrap();
        setNewBot({...emptyNewBot});
    };

    return (<>
        <TableRow hover role="checkbox" tabIndex={-1} key={'addNewBot'}>
            <TableCell key={'id'} align={'left'}></TableCell>
            <TableCell key={'port'} align={'left'}>
                <TextField 
                    id="input-port" 
                    required
                    type={'text'}
                    label={"@username"}
                    value={newBot.username}
                    size="small"
                    variant="standard"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleEdit<'username'>({key: 'username', value: event.target.value})} 
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" >
                            <AlternateEmail fontSize='small' />
                          </InputAdornment>
                        ),
                    }}
                />
            </TableCell>

            <TableCell key={'actions'} align={'right'}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    {/* <IconButton color="warning" aria-label="close" onClick={() => dispatch(setCreateNewBotIsOpen(false))}>
                        <CloseIcon />
                    </IconButton> */}
                    <IconButton color="success" aria-label="save" onClick={handleSaveNewBot}>
                        <DoneIcon />
                    </IconButton>
                </Grid>
            </TableCell>
        </TableRow>
    </>);
};

export default CreateNewBot;
