import { Check, Delete } from '@mui/icons-material';
import { Button, Stack, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { IUrlButtonData } from '.';

interface IEditUrlButtonProps {
    data: IUrlButtonData;
    onSave: (data: IUrlButtonData) => void;
    onRemove: () => void;
}

const EditUrlButton: FC<IEditUrlButtonProps> = (props) => {
    const [data, setData] = useState<IUrlButtonData>(props.data);

    return <>
        <Stack direction={'row'} sx={{width: '100%'}}>
            <Stack spacing={1} sx={{width: '100%'}}>
                <TextField
                    label="Текст"
                    value={data.text}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({...data, text: e.target.value})}
                    required
                    fullWidth
                />
                <TextField
                    label="Ссылка"
                    value={data.url}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({...data, url: e.target.value})}
                    required
                    fullWidth
                />
            </Stack>
            <Stack spacing={1}>
                <Button color='error' onClick={() => props.onRemove()} sx={{height: '100%'}}><Delete/></Button>
                <Button color='success' onClick={() => props.onSave(data)} sx={{height: '100%'}}><Check/></Button>
            </Stack>
        </Stack>
    </>
}

export default EditUrlButton;