import React, { FC } from "react"
import { Navigate, useParams } from "react-router-dom"
import BotsTable from "../components/bot/botsTable"
import { Box } from "@mui/material"

interface IBotsPageProps {}

const BotsPage: FC<IBotsPageProps> = (props) => {
  const { groupId } = useParams()

  if (!groupId || isNaN(+groupId)) {
    return <Navigate to={"../"} />
  }

  return (
    <Box width="100%">
      <BotsTable groupId={+groupId} />
    </Box>
  )
}

export default BotsPage
