import { FC, useState } from "react"
import { IFormProps } from "@/core/interfaces"
import { RtkHookForm } from "@/core/components"
import { ADV } from "../../interfaces"
import { useCreateOrderMutation, useGetOrderQuery } from "../../store"
import { DateTimePickerElement, DefaultValues, SwitchElement, TextFieldElement } from "react-hook-form-mui"
import { Box, Divider, Grid, Radio, Stack, Switch, Typography } from "@mui/material"
import { Agent } from "../formFields/agentsSelect"
import { Campaign } from "../formFields/campaignSelect"
import { Type } from "../formFields/typeSelect"
import { Links } from "../formFields/links"
import { DEFAULT_PAYMENT_SUM, HAS_PAYED_STATUS_ID } from "../../constants/adv"
import { encryptTransactionId } from "../utils/string"
import { PaymentFields } from "../formFields/paymentFields"
import { Urls } from "../formFields/urls"
import { Format } from "../formFields/formatSelect"
import { format } from "date-fns"
import { MembersLimit } from "../formFields/membersLimit"
import { ChatId } from "../formFields/chatSelect"
import { InviteLink } from "../formFields/inviteLink"

interface IProps extends IFormProps<ADV.CreateOrderRes, ADV.UpdateOrderRes> {
  id?: ADV.Order["id"]
  agentId?: ADV.Agent["id"]
}
export interface IOrderFormData extends ADV.CreateOrder {}

const DEFAULT_FORM_VALUES: DefaultValues<IOrderFormData> = {
  date_start: new Date(),
  status_id: HAS_PAYED_STATUS_ID,
  payment: {
    date_payment: new Date(),
    sum: DEFAULT_PAYMENT_SUM,
  },
  // links: [{ ...EMPTY_LINK }],
}
export const CreateOrderForm: FC<IProps> = (props) => {
  return (
    <RtkHookForm<
      IOrderFormData,
      ADV.CreateOrderRes,
      typeof useCreateOrderMutation,
      ADV.GetOrderRes,
      typeof useGetOrderQuery
    >
      mutationHook={useCreateOrderMutation}
      formContextParams={{
        defaultValues: { ...DEFAULT_FORM_VALUES, agent_id: props.agentId },
      }}
      getSubmitHandlerParams={async (data) => {
        return {
          body: {
            ...data,
            agent_id: data.agent_id ?? props.agentId,
            payment: data.payment && {
              ...data.payment,
              hash:
                data.payment?.hash &&
                (await encryptTransactionId(data.payment.hash)),
            },
          },
        }
      }}
      actions={[
        {
          text: "Сохранить",
          type: "submit",
          variant: "contained",
          fullWidth: true,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography variant="body1" pb={2}>
            Общее:
          </Typography>
          <Stack spacing={2}>
            <Agent<IOrderFormData> required readOnly={!!props.agentId} />
            <Campaign<IOrderFormData> required />
            <Type<IOrderFormData> required name={"type_id"} for={"orders"} />
            <Format<IOrderFormData> required />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography variant="body1" pb={2}>
            Оплата и размещение:
          </Typography>
          <PaymentFields<IOrderFormData> />
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Stack spacing={4}>
            <Box>
              <Typography variant="body1" pb={2}>
                Ссылки:
              </Typography>
              <Links<IOrderFormData> minLength={0} />
            </Box>
            <Divider />
            <Box>
              <Typography variant="body1" pb={2}>
                Дополнительные ссылки:
              </Typography>
              <Urls<IOrderFormData> minLength={0} />
            </Box>
            <Divider />
            <Box>
              <InviteLink />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </RtkHookForm>
  )
}
