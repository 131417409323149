import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import React, { FC } from "react"
import { IPostScheduleSchedule, WeekDay } from "../../../interface/interface"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 11 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface IScheduleConstructorProps {
  schedule: IPostScheduleSchedule
  onChange: (schedule: IPostScheduleSchedule) => void
  index?: number | string
}

const weekdays = [
  { name: "Пн", key: WeekDay.MONDAY },
  { name: "Вт", key: WeekDay.TUESDAY },
  { name: "Ср", key: WeekDay.WEDNESDAY },
  { name: "Чт", key: WeekDay.THURSDAY },
  { name: "Пт", key: WeekDay.FRIDAY },
  { name: "Сб", key: WeekDay.SATURDAY },
  { name: "Вс", key: WeekDay.SUNDAY },
]
const hours = Array.from(new Array(24).keys())
const minutes = Array.from(new Array(60).keys())

const ScheduleConstructor: FC<IScheduleConstructorProps> = (props) => {
  const handleWeekdaysChange = (
    value: WeekDay[] | string,
  ) => {
    const res =
      typeof value === "string"
        ? []
        : value.sort((l, r) =>
            l === WeekDay.SUNDAY || r === WeekDay.SUNDAY ? r - l : l - r,
          )
    props.onChange({ ...props.schedule, weekDays: [...res] })
  }
  const handleHoursChange = (value: number[] | string) => {
    const res = typeof value === "string" ? [] : value.sort((l, r) => l - r)
    console.log("handleHoursChange", value)

    props.onChange({ ...props.schedule, hour: [...res] })
  }
  const handleMinutesChange = (value: number[] | string) => {
    const res = typeof value === "string" ? [] : value.sort((l, r) => l - r)
    props.onChange({ ...props.schedule, minute: [...res] })
  }

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant="body2" paragraph>
            Дата должна быть задана в часовом поясе{" "}
            {import.meta.env.REACT_APP_SCHEDULE_TZ ?? "Europe/Moscow"}!
          </Typography>
          <FormControl fullWidth>
            <InputLabel id={`weekdays-select-${props.index ?? 0}`}>
              {props.schedule.weekDays.length ? "Дни недели" : "Каждый день"}
            </InputLabel>
            <Select
              labelId={`weekdays-select-${props.index ?? 0}`}
              id="weekdays-checkbox"
              multiple
              value={props.schedule?.weekDays}
              onChange={(e) => handleWeekdaysChange(e.target.value)}
              input={
                <OutlinedInput
                  label={
                    props.schedule.weekDays.length
                      ? "Дни недели"
                      : "Каждый день"
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={weekdays.find((wd) => wd.key === value)?.name}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {weekdays.map(({ name, key }) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={props.schedule?.weekDays.includes(key)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            <Box>
              <Button color="secondary" onClick={() => handleWeekdaysChange([])}>
                Каждый день
              </Button>
              <Button color="secondary"
                onClick={() =>
                    handleWeekdaysChange(
                    Array.from(new Array(5).keys()).map((v) => v + 2),
                  )
                }
              >
                Будни
              </Button>
              <Button color="secondary"
                onClick={() =>
                    handleWeekdaysChange(
                    Array.from([1, 7]),
                  )
                }
              >
                Выходные
              </Button>
            </Box>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            sx={{
              height: "100%",
            }}
          >
            <InputLabel id={`hours-select-${props.index ?? 0}`}>
              {props.schedule.hour.length ? "Часы" : "Каждый час"}
            </InputLabel>
            <Select
              labelId={`hours-select-${props.index ?? 0}`}
              id="hours-checkbox"
              multiple
              value={props.schedule?.hour}
              placeholder={"Каждый час"}
              onChange={(e) => handleHoursChange(e.target.value)}
              input={
                <OutlinedInput
                  label={props.schedule.hour.length ? "Часы" : "Каждый час"}
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value.toString().padStart(2, "0")}
                    />
                  ))}
                </Box>
              )}
              sx={{
                height: "100%",
              }}
              MenuProps={MenuProps}
            >
              {hours.map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox checked={props.schedule?.hour.includes(value)} />
                  <ListItemText primary={value.toString().padStart(2, "0")} />
                </MenuItem>
              ))}
            </Select>
            <Box>
              <Button color="secondary" onClick={() => handleHoursChange([])}>Раз в час</Button>
              <Button color="secondary"
                onClick={() =>
                  handleHoursChange(
                    Array.from(new Array(24 / 2).keys()).map((v) => v * 2),
                  )
                }
              >
                Раз в два часа
              </Button>
              <Button color="secondary"
                onClick={() =>
                  handleHoursChange(
                    Array.from(new Array(24 / 3).keys()).map((v) => v * 3),
                  )
                }
              >
                Раз в три часа
              </Button>
              <Button color="secondary"
                onClick={() =>
                  handleHoursChange(
                    Array.from(new Array(24 / 4).keys()).map((v) => v * 4),
                  )
                }
              >
                Раз в четыре часа
              </Button>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id={`minutes-select-${props.index ?? 0}`}>
              {props.schedule.minute.length ? "Минуты" : "Каждую минуту"}
            </InputLabel>
            <Select
              labelId={`minutes-select-${props.index ?? 0}`}
              id="minutes-checkbox"
              multiple
              value={props.schedule?.minute}
              onChange={(e) => handleMinutesChange(e.target.value)}
              input={
                <OutlinedInput
                  label={
                    props.schedule.minute.length ? "Минуты" : "Каждую минуту"
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value.toString().padStart(2, "0")}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {minutes.map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox checked={props.schedule?.minute.includes(value)} />
                  <ListItemText primary={value.toString().padStart(2, "0")} />
                </MenuItem>
              ))}
            </Select>
            <Box>
              <Button color="secondary" onClick={() => handleMinutesChange([])}>
                Каждую минуту
              </Button>
              <Button color="secondary"
                onClick={() =>
                  handleMinutesChange(
                    Array.from(new Array(4).keys()).map((v) => (v * 60) / 4),
                  )
                }
              >
                Четыре раза в час
              </Button>
              <Button color="secondary"
                onClick={() =>
                  handleMinutesChange(
                    Array.from(new Array(2).keys()).map((v) => (v * 60) / 2),
                  )
                }
              >
                Два раза в час
              </Button>
              <Button color="secondary" onClick={() => handleMinutesChange([0])}>
                Один раз в час
              </Button>
            </Box>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default ScheduleConstructor
