import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, FormControlLabel, IconButton, Menu, MenuItem, Switch, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IAd } from '../../interface/interface';
import { useDeletePostMutation, useUpdatePostMutation } from '../../services/posts/postsApi';

interface IPostCardProps extends IAd {}

const PostCard: FC<IPostCardProps> = (props) => {
    const { post } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [deletePost] = useDeletePostMutation();
    const [updatePost] = useUpdatePostMutation();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const setEnabled = (enabled: boolean) => {
        updatePost({
            id: props.id,
            isEnabled: enabled,
        })
    }

    return (<>
        <Card sx={(theme) => ({
            backgroundColor: (props.isEnabled ? theme.palette.background.default : theme.palette.action.disabledBackground), 
            color: (props.isEnabled ? theme.palette.text.primary : theme.palette.text.disabled),
        })}>
            <CardHeader
                action={
                    <IconButton 
                        id="menu-button"
                        aria-label="settings" 
                        onClick={handleClick}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVert />
                    </IconButton>
                }
                title={!!props.title ? props.title : props.post.text.slice(0, 30)}
                subheader={!!props.note && <Typography variant='caption'>{props.note}</Typography>}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => deletePost(props.id)}><Delete color='error'/> Удалить</MenuItem>
            </Menu>
            {!!post.media?.length && <CardMedia
                component="img"
                alt="post image"
                height="200"
                image={post.media?.at(0)?.data as string}
            />}
            <CardContent>
                <Typography gutterBottom variant="body1" component="p">
                    {post.text}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    id:{props.id}
                </Typography>
            </CardContent>
            <Divider />
            <CardActions sx={{justifyContent: 'space-between'}}>
                <Button onClick={() => navigate(`${props.id}`, {
                    state: {
                        backgroundLocation: location,
                    }
                })} size="small"><Edit />&nbsp;Редактировать</Button>
                <FormControlLabel
                    control={
                        <Switch checked={props.isEnabled} onChange={(e, value) => setEnabled(value)} name="switch-post-enabled" />
                    }
                    label={props.isEnabled ? "Включен" : "Выключен"}
                />
            </CardActions>
        </Card>
    </>);
};

export default PostCard;
