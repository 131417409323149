import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Snackbar,
  TextField,
} from "@mui/material"
import { Stack } from "@mui/system"
import React, { FC, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { IAd, ICreatePost, IPost, IUpdatePost } from "../../interface/interface"
import {
  useCreatePostMutation,
  useGetPostQuery,
  useSendPreviewMutation,
  useUpdatePostMutation,
} from "../../services/posts/postsApi"
import PostTextField from "./postField/textPost"
import Schedule from "./schedule"
import BotsGroupSelectorField from "./botsSelector/botsSelector"

interface IPostFormProps {
  id?: number
  channelId: number
}

const validate = (values: ICreatePost | IUpdatePost): boolean | string => {
  if (!values.post.text && !values.post.media?.length) {
    return "Пост должен содержать текст или медиа!"
  }
  if (!values.schedule?.schedules?.length) {
    return "Нужно добавить хотябы одно расписание или интервал!"
  }

  return true
}

/**
 * @todo
 * нужно избавиться от этого
 */
const convertDataToValues = (data?: IAd) => {
  if (!!data) {
    const {
      post,
      schedule,
      isEnabled,
      id,
      botsGroup,
      referal,
      note,
      title,
      channel: { id: channelId },
    } = data

    return {
      isEnabled,
      channelId,
      id,
      referal,
      note,
      title,
      botsGroup,
      post: { ...post },
      schedule: { ...schedule, schedules: [...schedule.schedules] },
    }
  }
}
const needBotAutoReplace = (post: IPost) =>
  post.text?.includes("{bot}") ||
  post.reply_markup?.inline_keyboard
    .flat(2)
    .find((b) => b.url?.includes("{bot}"))

const PostForm: FC<IPostFormProps> = (props) => {
  const { id, channelId } = props
  const initialData: ICreatePost = {
    post: {
      text: "",
      parseMode: undefined,
      entities: [],
      media: [],
      linkPreview: false,
    },
    botsGroupId: undefined,
    referal: "",
    isEnabled: true,
    schedule: {
      schedules: [],
    },
    channelId,
    note: "",
    title: "",
  }
  const { isLoading, isError, data } = useGetPostQuery(!!id ? id : 0, {
    refetchOnFocus: true,
    skip: !id,
  })
  const [initialized, setInitialized] = useState<boolean>(false)
  const [values, setValues] = useState<ICreatePost | IUpdatePost>(
    convertDataToValues(data) ?? initialData,
  )
  const [savedSuccessfullyAlertOpen, setSavedSuccessfullyAlertOpen] =
    useState(false)
  const [previewSended, setPreviewSended] = useState(false)
  const [needBotsSelector, setNeedBotsSelector] = useState(
    needBotAutoReplace(values.post),
  )
  const [showBotConnection, setShowBotConnection] = useState(false)

  const [
    updatePost,
    {
      isLoading: isUpdatePostLoading,
      isError: isUpdatePostError,
      isSuccess: isUpdatePostSuccess,
    },
  ] = useUpdatePostMutation()
  const [
    createPost,
    {
      isLoading: isCreatePostLoading,
      isError: isCreatePostError,
      isSuccess: isCreatePostSuccess,
    },
  ] = useCreatePostMutation()
  const [
    sendPreview,
    {
      isLoading: isSendPreviewLoading,
      isError: isSendPreviewError,
      isSuccess: isSendPreviewSuccess,
      error: sendPreviewError,
    },
  ] = useSendPreviewMutation()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setValues(convertDataToValues(data) ?? initialData)
  }, [data])
  useEffect(() => {
    if (isLoading) {
      return
    }
    setInitialized(true)
  }, [values])
  useEffect(() => {
    setNeedBotsSelector(needBotAutoReplace(values.post))
  }, [values.post.text, values.post.reply_markup?.inline_keyboard])
  useEffect(() => {
    if (!needBotsSelector) {
      setValues({ ...values, botsGroupId: undefined })
    }
  }, [needBotsSelector])
  useEffect(() => {
    if (isUpdatePostSuccess || isCreatePostSuccess) {
      setSavedSuccessfullyAlertOpen(true)
    }
  }, [isUpdatePostSuccess, isCreatePostSuccess])
  useEffect(() => {
    if (isSendPreviewSuccess) {
      setPreviewSended(true)
    }
  }, [isSendPreviewSuccess])
  useEffect(() => {
    if (
      isSendPreviewError &&
      sendPreviewError &&
      typeof sendPreviewError === "object" &&
      "status" in sendPreviewError
    ) {
      if (sendPreviewError.status === 403) {
        setShowBotConnection(true)
      }
    }
  }, [isSendPreviewError])

  const savePost = () => {
    const valid = validate(values)
    if (typeof valid === "string") {
      alert(valid)
      return
    }
    if ("id" in values) {
      updatePost({ ...values }).unwrap()
    } else {
      createPost({ ...values })
        .unwrap()
        .then((res) => {
          navigate(location.pathname.replace(/[^/]*$/, `${res.id}`), {
            replace: true,
            state: location.state,
          })
        })
    }
  }

  if (isLoading || !initialized) {
    return <LinearProgress />
  }

  return (
    <>
      <Box component="form">
        <Stack my={2} spacing={5}>
          {isError && (
            <Alert severity="error">
              Во время загрузки поста произошла ошибка!
            </Alert>
          )}
          {(isUpdatePostError || isCreatePostError) && (
            <Alert severity="error">
              Во время сохранения поста произошла ошибка!
            </Alert>
          )}
          {isSendPreviewError && <Alert severity="error"><AlertTitle>Во время отправки предпросмотра произошла ошибка!</AlertTitle>Проверьте форматирование</Alert>}

          <PostTextField
            values={values.post}
            onChange={(post) => setValues({ ...values, post })}
          />

          {needBotsSelector && (
            <BotsGroupSelectorField
              id={values.botsGroup?.id}
              onChangeSelectedBotsGroup={(botsGroupId) =>
                setValues({ ...values, botsGroupId })
              }
              onChangeReferal={(referal) => setValues({ ...values, referal })}
            />
          )}

          <Schedule
            schedule={values.schedule}
            onChange={(schedule) => setValues({ ...values, schedule })}
          />

          <Stack spacing={1}>
            <TextField
              value={values.title ?? ""}
              onChange={(e) =>
                setValues({ ...values, title: e.currentTarget.value })
              }
              label={"Название"}
              inputProps={{
                maxLength: 50,
              }}
              fullWidth
            />
            <TextField
              value={values.note ?? ""}
              onChange={(e) =>
                setValues({ ...values, note: e.currentTarget.value })
              }
              label={"Заметка"}
              multiline
              rows={3}
              inputProps={{
                maxLength: 500,
              }}
              fullWidth
            />
          </Stack>

          <Stack spacing={1}>
            <Button
              onClick={() => sendPreview(values)}
              disabled={isSendPreviewLoading}
              variant="outlined"
            >
              Предпросмотр поста&nbsp;
              {isSendPreviewLoading && <CircularProgress size={20} />}
            </Button>
            <Button
              onClick={savePost}
              disabled={isUpdatePostLoading || isCreatePostLoading}
              variant="contained"
            >
              Сохранить&nbsp;
              {(isUpdatePostLoading || isCreatePostLoading) && (
                <CircularProgress size={20} />
              )}
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Snackbar
        open={savedSuccessfullyAlertOpen}
        autoHideDuration={3000}
        onClose={() => setSavedSuccessfullyAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ minWidth: "60%" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          <AlertTitle>Сохранено</AlertTitle>Пост сохранён успешно!
        </Alert>
      </Snackbar>
      <Snackbar
        open={previewSended}
        autoHideDuration={3000}
        onClose={() => setPreviewSended(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ minWidth: "60%" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          <AlertTitle>Выполнено</AlertTitle>Пост отправлен вам в телеграм бота!
        </Alert>
      </Snackbar>
    </>
  )
}

export default PostForm
