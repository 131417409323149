import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { FC } from 'react';

interface IAddNewProps {
    onClick?: (...args: any) => unknown;
}

const AddNew: FC<IAddNewProps> = (props) => {
    return (<>
        <Button 
            variant='outlined' 
            sx={{width: '100%', height: '100%', minHeight: '100px'}}
            onClick={props.onClick}
        >
            <AddIcon fontSize='large'/>
        </Button>
    </>);
};

export default AddNew;
