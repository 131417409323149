import React, { FC } from "react"
import { useNavigate, useParams } from "react-router-dom"
import PostsList from "../components/posts/PostsList"
import { IChannel } from "../interface/interface"
import { Box } from "@mui/material"
import { GoBack } from "@/core/components"

interface IChannelsPageProps {}

const DetailedChannelPage: FC<IChannelsPageProps> = (props) => {
  const { channelId } = useParams()
  const navigate = useNavigate()

  if (!channelId) {
    navigate("/")
    return <></>
  }

  return (
    <Box width="100%">
      <GoBack />
      <PostsList channelId={+channelId} />
    </Box>
  )
}

export default DetailedChannelPage
