import { Add as AddIcon } from '@mui/icons-material';
import { Paper, Popover, Typography } from '@mui/material';
import React, { FC } from 'react';

interface IAddNewProps {}

const AddNew: FC<IAddNewProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (<>
        <Paper 
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{width: '100%', height: '100%', minHeight: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
            <AddIcon fontSize='large'/>
        </Paper>
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Typography sx={{ p: 1, maxWidth: "400px" }} variant='body1'>
                Чтобы добавить канал или группу, добавьте в него бота.
                У бота должна быть возможность публиковать сообщения.
            </Typography>
        </Popover>
    </>);
};

export default AddNew;
