import { FileObject } from 'mui-file-dropzone';
import { InlineKeyboardButton, MessageEntity, ParseMode, ApiMethods } from 'typegram';

export type ChatType = 'channel' | 'supergroup' | 'group' | 'private';
export type UserStatus = "creator" | "administrator" | "member" | "restricted" | "left" | "kicked";

export type ApiSendMessage = Pick<ApiMethods<any>, 
    'sendAnimation'
    | 'sendAudio'
    | 'sendContact'
    | 'sendDice'
    | 'sendDocument'
    | 'sendMediaGroup'
    | 'sendMessage'
    | 'sendLocation'
    | 'sendPhoto'
    | 'sendPoll'
    | 'sendSticker'
    | 'sendVenue'
    | 'sendVideo'
    | 'sendVideoNote'
    | 'sendVoice'
    | 'sendGame'
>
export type Post<M extends keyof ApiSendMessage> = Omit<Parameters<ApiMethods<any>[M]>[0], 'chat_id'>
export type TelegramPost = Post<"sendMessage"> 
    | Post<"sendPhoto"> 
    | Post<"sendVideo"> 
    | Post<"sendAnimation"> 
    | Post<"sendVoice"> 
    | Post<"sendAudio"> 
    | Post<"sendDocument"> 
    | Post<"sendVideoNote"> 
    | Post<"sendSticker"> 
    | Post<"sendLocation">;
export enum ScheduleType {
    INTERVAL = 'interval',
    SCHEDULE = 'schedule',
}
export enum WeekDay {
    SUNDAY = 1,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
}
export enum IntervalPeriod {
    DAY = 'd',
    HOUR = 'h',
    MINUTE = 'm',
}
export interface IPostScheduleInterval {
    type: ScheduleType.INTERVAL;
    period: IntervalPeriod;
    value: number;
}
export interface IPostScheduleSchedule {
    type: ScheduleType.SCHEDULE;
    weekDays: WeekDay[],
    hour: number[];
    minute: number[];
}
export interface IPostSchedule {
    schedules: (IPostScheduleInterval | IPostScheduleSchedule)[];
    dateStart?: Date | string;
    dateEnd?: Date | string;
};

export enum BotStatus {
    NEW = 'new',
    IN_USE = 'in_use',
    BANNED = 'banned',
}
export interface IBotsToGroup {
    id: number;
    bot_id: IBot['id'];
    group_id: IBotsGroup['id'];
    order: number;
    group: IBotsGroup;
    bot: IBot;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
}
export interface IBotsGroup {
    id: number;
    name: string;
    referral_code: string;
    note: string;
    botsToGroup: IBotsToGroup[];
    posts: IPost[];
    created_at: Date | string;
    updated_at: Date | string;
    deleted_at: Date | string;
}
export interface ICreateBotsGroup {
    name: string;
    referral_code?: string;
    note?: string;
}
export interface IDeleteBotParams {
    botId: number;
    groupId: number;
}
export interface IBot {
    id: number;
    username: string;
    status: BotStatus;
}
export interface IChannel {
    id: number;
    title: string;
    type: ChatType;
    status: UserStatus;
    link: string;
    members_count: number;
    posts: IPost[];
    created_at: Date | string;
    updated_at: Date | string;
    deleted_at: Date | string;
}
export interface IPost {
    text: string;
    entities?: MessageEntity[];
    media?: FileObject[];
    parseMode?: ParseMode;
    reply_markup?: {inline_keyboard: InlineKeyboardButton.UrlButton[][]};
    linkPreview?: boolean;
    pin?: boolean;
}
export type ICreatePost = Omit<IAd, 'channel' | 'id' | 'bots' | 'created_at' | 'updated_at' | 'deleted_at'> & {
    channelId: number;
    botsGroupId?: IBotsGroup['id'];
}
export interface IAd {
    id: number;
    channel: IChannel;
    post: IPost;
    schedule: IPostSchedule;
    isEnabled: boolean;
    botsGroup?: IBotsGroup;
    referal?: string;
    note?: string;
    title?: string;
    created_at: Date | string;
    updated_at: Date | string;
    deleted_at: Date | string;
}
export type IUpdatePost = ICreatePost & Pick<IAd, 'id'>
export type ICreateBot = Omit<IBot, 'id'> & { botsGroupId: IBotsGroup['id'] }