import {
  Stack,
  Button,
  Box,
  Switch,
  Typography,
  FormControlLabel,
} from "@mui/material"
import {
  DateTimePickerElement,
  FieldValues,
  SwitchElement,
  TextFieldElement,
  useFormContext,
} from "react-hook-form-mui"
import { Type } from "./typeSelect"
import { RefCode } from "./refCodesSelect"
import { EMPTY_LINK } from "@/modules/adv2/constants/adv"
import { IOrderFormData } from "../forms/createOrderForm"
import { Bot } from "./botSelect"
import { ADV } from "../../interfaces"
import { format } from "date-fns"
import { ChatId } from "./chatSelect"
import { MembersLimit } from "./membersLimit"
import { useEffect, useState } from "react"

interface IProps<T extends FieldValues> {}
interface IField extends ADV.InviteLink {}

export function InviteLink<T extends FieldValues = IField>(
  props: IProps<T>,
): JSX.Element {
  const [showInviteLinks, setShowInviteLinks] = useState(false)
  const ctx = useFormContext<IOrderFormData>()

  useEffect(() => {
    if (!showInviteLinks) {
      ctx.unregister("invite_link")
    }
  })

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            value={showInviteLinks}
            onChange={(_, checked) => setShowInviteLinks(checked)}
          />
        }
        label={
            "Пригласительная ссылка"
        }
        // labelPlacement="start"
      />
      {showInviteLinks && (
        <Stack spacing={1}>
          <ChatId<IOrderFormData> required name="invite_link.chat_id" />
          <TextFieldElement<IOrderFormData>
            name="invite_link.name"
            label="Имя"
          />
          <TextFieldElement<IOrderFormData>
            name="invite_link.comment"
            label="Комментарий"
            multiline
            rows={3}
          />
          <SwitchElement<IOrderFormData>
            name="invite_link.creates_join_request"
            label="Вступление по заявкам"
          />
          <MembersLimit<IOrderFormData> />
          <DateTimePickerElement<IOrderFormData>
            name="invite_link.expire_date"
            label="Действительна до"
            disablePast
            helperText={
              <Typography variant="caption">
                Часовой пояс: {format(Date.now(), "OOOO")}
              </Typography>
            }
          />
        </Stack>
      )}
    </>
  )
}
