import { ab2str, encrypt, str2ab } from "@/core/utils"

export const formatTGLink = (str: string | undefined): string | undefined => {
  return isURL(str) ? str : `https://t.me/${str}`
}
export const isURL = (str: string | undefined): boolean => {
  return !!str?.match(/^https?:\/\//i)
}

export const encryptTransactionId = async (id: string): Promise<string> =>
  btoa(
    ab2str(
      await encrypt(
        str2ab(id),
        import.meta.env.REACT_APP_ADV_CRYPTO_PUBLIC_KEY,
      ),
    ),
  )
