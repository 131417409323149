import { Alert, Grid, LinearProgress } from '@mui/material';
import React, { FC } from 'react';
import { useChannelsQuery } from '../../services/channels/channelsApi';
import AddNew from './addNew';
import ChannelCard from './channelCard';

interface IChannelsListProps {}

const ChannelsList: FC<IChannelsListProps> = (props) => {
    const {isLoading, isError, data} = useChannelsQuery({
        refetchOnFocus: true
    })

    return (<>
        {isError && <Alert severity="error">Во время загрузки списка каналов произошла ошибка!</Alert>}
        {isLoading && <LinearProgress />}
        <Grid container spacing={2} p={2}>
            {!!data?.length && data.map(ch => <Grid item xs={12} md={6} xl={4} key={`${ch.id}`} >
                <ChannelCard {...ch} />
            </Grid>)}
            {!data?.length && !isError && !isLoading && <Grid item xs={12} md={6} xl={4} >
                <Alert 
                    sx={{
                        width: '100%',
                        height: '100%',
                        minHeight: '100px',
                        display: 'flex',
                        padding: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }} 
                    severity='info'
                >
                    Каналов нет
                </Alert>
            </Grid>}
            <Grid item xs={12} md={6} xl={4} >
                <AddNew />
            </Grid>
        </Grid>
    </>);
};

export default ChannelsList;
