import React, {FC} from 'react';
import ChannelsList from '../components/channels/channelsList';
import { Box } from '@mui/material';

interface IChannelsPageProps {
    
}

const ChannelsPage: FC<IChannelsPageProps> = (props) => {
    return (
        <Box width="100%">
            <ChannelsList/>
        </Box>
    );
};

export default ChannelsPage;