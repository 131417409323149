import { FormControl, InputAdornment, InputLabel, OutlinedInput, styled, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { IntervalPeriod, IPostScheduleInterval, ScheduleType } from '../../../interface/interface';

interface IIntervalScheduleProps {
    schedule: IPostScheduleInterval;
    onChange: (schedule: IPostScheduleInterval) => void;
    index?: string | number;
}
interface IDateBounds {
    min: number;
    max: number;
}
const getBounds = (period: IntervalPeriod): IDateBounds => {
    if (period === IntervalPeriod.MINUTE) {
        return {min: 1, max: 59};
    } else if (period === IntervalPeriod.HOUR) {
        return {min: 1, max: 23};
    } else if (period === IntervalPeriod.DAY) {
        return {min: 1, max: 365};
    }
    throw new Error('Value out of range');
}

const IntervalSchedule: FC<IIntervalScheduleProps> = (props) => {
    const [bounds, setBounds] = useState<IDateBounds>(getBounds(props.schedule.period));

    useEffect(() => {
        const b = getBounds(props.schedule.period);
        setBounds(b);
        
        if (props.schedule.value > b.max) {
            handleValueChange(b.max);
        } else if (props.schedule.value < b.min) {
            handleValueChange(b.min);
        }
    }, [props.schedule.period, props.schedule.value]);

    const handlePeriodChange = (period: IntervalPeriod) => {
        if (period === null) {
            return;
        }
        props.onChange({ ...props.schedule, period })
    }
    const handleValueChange = (value: number) => {
        props.onChange({ ...props.schedule, value })
    }

    return (<>
        <FormControl fullWidth>
            <InputLabel id={`interval-input-${props.index ?? 0}`}>Каждые</InputLabel>
            <OutlinedInput 
                id={`interval-input-${props.index ?? 0}`}
                label="Каждые"
                type={'number'}
                inputProps={bounds}
                fullWidth
                value={props.schedule.value}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleValueChange(+event.target.value)}
                sx={{
                    alignItems: 'stretch',
                    paddingRight: 0,
                }}
                endAdornment={
                    <InputAdornment position="end" variant='standard' sx={{
                        alignItems: 'stretch',
                        height: 'inherit',
                        maxHeight: 'inherit',
                    }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={props.schedule.period}
                            exclusive
                            onChange={(_e: React.MouseEvent<HTMLElement, MouseEvent>, val: IntervalPeriod) => handlePeriodChange(val)}
                            aria-label="parse_mode"
                            sx={{
                                border: 0,
                                alignSelf: 'stretch',
                                height: 'inherit',
                                maxHeight: 'inherit',
                                '> *': {
                                    borderRadius: 0,
                                    borderWidth: 'none',
                                    border: 0,
                                },
                                '>:not(:last-child)': {
                                    borderRight: 1,
                                    borderColor: theme => theme.palette.divider
                                }
                            }}
                        >
                            <ToggleButton value={IntervalPeriod.MINUTE}>минут</ToggleButton>
                            <ToggleButton value={IntervalPeriod.HOUR}>часов</ToggleButton>
                            <ToggleButton value={IntervalPeriod.DAY}>дней</ToggleButton>
                        </ToggleButtonGroup>
                    </InputAdornment>
                }
            />
        </FormControl>
    </>);
};

export default IntervalSchedule;
