import { AutocompleteElement, FieldValues, Path, useWatch } from "react-hook-form-mui"
import { useGetMyChatsQuery } from "@/modules/tgstatBot/store"
import { TgstatBot } from "@/modules/tgstatBot/interfaces"

interface IProps<T extends FieldValues> {
  name: Path<T>
  required?: boolean
  disabled?: boolean
}
type IField = {
  chat_id: TgstatBot.Chat["bot_id"] | undefined
}

export const ChatId = <T extends FieldValues = IField>(props: IProps<T>) => {
  const { required, disabled, name } = props
  const { data: dataAndTotal, isFetching: isLoading } = useGetMyChatsQuery({
    queryParams: {
      only_current_bot: true
    },
  })
  const [data, _totalCount] = dataAndTotal ?? [];

  return (
    <AutocompleteElement<T>
      name={name}
      label="Группа"
      loading={isLoading}
      matchId
      required={required}
      autocompleteProps={{
        disabled,
        fullWidth: true,
      }}
      options={data?.map(v => ({id: +v.chat_id, label: v.chat_title})) ?? []}
    />
  )
}
