import { Close, FormatBold, FormatItalic, Info } from "@mui/icons-material"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import React, { FC, useState } from "react"
import { MessageEntity } from "typegram"
import { DropzoneAreaBase } from "mui-file-dropzone"
import { IPost } from "../../../interface/interface"
import UrlButtons from "./urlButtons"

interface IRange {
  from: number
  to: number
}
interface IPostTextFieldProps {
  values: IPost
  onChange: (val: IPost) => void
}

const PostTextField: FC<IPostTextFieldProps> = (props) => {
  const { values, onChange } = props
  const [selection, setSelection] = useState<IRange | null>(null)

  // const [ initialFiles, setInitialFiles ] = useState<File[]>([]);

  // useEffect(() => {
  //     (async () => {
  //         if (!values.media) {
  //             return;
  //         }
  //         const files = await Promise.all(values.media.map(f =>
  //             fetch(f.data as string)
  //             .then(res => {console.log(res); return res})
  //             .then(res => res.arrayBuffer()
  //                 .then(buf => new File([buf], '1234', {type: 'image/jpeg'}))
  //         )));
  //         console.log(files);

  //         setInitialFiles(files);
  //     })();
  // }, []);

  const handleTextSelection = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.selectionStart === null || target.selectionEnd === null) {
      setSelection(null)
      return
    }

    setSelection({ from: target.selectionStart, to: target.selectionEnd })
  }

  const setEntities = (entity: MessageEntity) => {
    const entities = [...(values.entities ?? [])]
    const currentIndex = entities?.findIndex(
      (val) =>
        val.type === entity.type &&
        val.offset <= entity.offset &&
        val.offset + val.length >= entity.offset + entity.length,
    )

    if (typeof currentIndex === "number" && currentIndex > -1) {
      entities.splice(currentIndex, 1)
    } else if (entity.length > 0) {
      entities.push(entity)
    }

    onChange({ ...values, entities })
  }
  const onEntityChange = (type: "bold" | "italic") => {
    if (!selection) {
      return
    }
    setEntities({
      type,
      offset: selection.from,
      length: selection.to - selection.from,
    })
  }
  const getEntitiesBySelection = (range: IRange): MessageEntity[] => {
    return (
      values.entities?.filter(
        (val) =>
          val.offset <= range.from && val.offset + val.length >= range.to,
      ) ?? []
    )
  }

  const onDeleteFile = (index: number) => {
    // props.values.media?.splice(index);
    // props.onChange({...props.values, media: [...(props.values.media ?? [])]});
    props.onChange({ ...props.values, media: [] })
  }

  return (
    <Stack spacing={2}>
      <Stack>
        {/* <ToggleButtonGroup
                color="primary"
                value={selection ? getEntitiesBySelection(selection).map(val => val.type) : []}
                aria-label="parse_mode"
                size='small'
                disabled={!selection}
                sx={{
                    justifyContent: 'flex-end',
                }}
            >
                <ToggleButton value={'bold' as const} onClick={() => onEntityChange('bold')} ><FormatBold/></ToggleButton>
                <ToggleButton value={'italic' as const} onClick={() => onEntityChange('italic')}><FormatItalic/></ToggleButton>
            </ToggleButtonGroup> */}
        <TextField
          id="outlined-multiline-flexible"
          label="Текст"
          multiline
          rows={10}
          sx={{ textarea: { resize: "vertical" } }}
          value={values.text}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...values, text: e.target.value })
          }
          onSelect={handleTextSelection}
          required
        />
        <ToggleButtonGroup
          color="primary"
          value={values.parseMode}
          exclusive
          size="small"
          onChange={(_e, val) => onChange({ ...values, parseMode: val })}
          aria-label="parse_mode"
          sx={{
            "> *": {
              flexGrow: 1,
            },
          }}
        >
          <ToggleButton value="MarkdownV2">MarkdownV2</ToggleButton>
          <ToggleButton value="Markdown">Markdown</ToggleButton>
          <ToggleButton value="HTML">HTML</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="caption">
          <Info
            color="disabled"
            fontSize="small"
            sx={{ verticalAlign: "middle" }}
          />
          Чтобы сделать ссылку на бота, который в случае блокировки
          автоматически заменится на другого, напишите {"{bot}"}
          <br />
          Вместо {"{bot}"} будет подставлено
          https://t.me/username_bot?start=referal
          <br />
          Список ботов и реферальный код можно будет задать ниже
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                (values.linkPreview && !props.values.media?.length) ?? false
              }
              onChange={(e, val) => onChange({ ...values, linkPreview: val })}
            />
          }
          label="Предпросмотр ссылок"
          disabled={!!props.values.media?.length}
        />
      </Stack>
      {props.values.media?.length ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={props.values.media[0].data as string}
            style={{ width: 300 }}
            alt="post media"
          />
          <Button onClick={() => onDeleteFile(0)}>
            <Close />
            Удалить
          </Button>
        </Box>
      ) : (
        <DropzoneAreaBase
          fileObjects={[]}
          acceptedFiles={["image/*"]}
          dropzoneText={"Добавьте изображение"}
          maxFileSize={20000000}
          // showAlerts={false}
          dropzoneProps={{
            multiple: false,
          }}
          filesLimit={1}
          onAdd={(files) =>
            props.onChange({
              ...props.values,
              media: [...files, ...(props.values.media ?? [])],
            })
          }
          onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
          // onDelete={onDeleteFile}
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={!!values.pin}
            onChange={(e, val) => onChange({ ...values, pin: val })}
          />
        }
        label="Закрепить после публикации"
      />

      <UrlButtons
        data={values.reply_markup}
        onChange={(data) =>
          props.onChange({ ...props.values, reply_markup: data })
        }
      />
    </Stack>
  )
}

export default PostTextField
