import {BaseQueryFn, createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { IChannel } from '../../interface/interface';
import { dynamicMiddlewaresInstance, registerReducer, RootState } from '@/core/store';
import { baseQueryWithReauth } from '@/modules/app/store';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

const tagTypes = ['Channels'] as const

const reducerPath: string = "channelApi"
export const baseTGStatApiURL = new URL(
  import.meta.env.REACT_APP_POSTBOT_API,
  window.location.origin,
).toString()
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: `${
    baseTGStatApiURL.endsWith("/") ? baseTGStatApiURL : baseTGStatApiURL + "/"
  }`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const endpoints = (build: ApiEndpointBuider) => ({
  channels: build.query<IChannel[], {}>({
    query: () => ({
      url: `channel`,
      method: 'GET'
    }),
    transformResponse: (data: IChannel[]) => data.sort((l, r) => ['kicked', 'left', 'restricted'].includes(l.status) ? 1 : -1),
    providesTags: ['Channels'],
  }),
  
})

export const channelApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery: (...args) => baseQueryWithReauth(baseQuery, args),
  endpoints: (build) => ({
    ...endpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(channelApi.middleware)
registerReducer(channelApi.reducerPath, channelApi.reducer)

export const {
  useChannelsQuery,
} = channelApi;