import { Grid, IconButton, InputAdornment, TableCell, TableRow, TextField } from '@mui/material';
import React, {FC, useState} from 'react';
import { Done as DoneIcon, AlternateEmail } from '@mui/icons-material';
import { ICreateBotsGroup } from '../../interface/interface';
import { useCreateBotsGroupMutation } from '../../services/bots/botsGroupApi';

interface ICreateNewBotProps {}

const emptyNewBot: ICreateBotsGroup = {
    name: '',
    note: '',
    referral_code: '',
}

const CreateNewBotsGroup: FC<ICreateNewBotProps> = () => {
    const [createBotsGroup] = useCreateBotsGroupMutation();
    const [newBotsGroup, setNewBotsGroup] = useState(emptyNewBot);

    const handleEdit: <T extends keyof ICreateBotsGroup>(val: {key: T, value: ICreateBotsGroup[T]}) => void = (val) => {
        const bot = {...newBotsGroup};
        bot[val.key] = val.value;
        
        setNewBotsGroup(bot);
    }
    const handleSaveNewBot = async () => {
        await createBotsGroup(newBotsGroup).unwrap();
        setNewBotsGroup(emptyNewBot);
    };

    return (<>
        <TableRow hover role="checkbox" tabIndex={-1} key={'addNewBot'}>
            <TableCell key={'name'} align={'left'}>
                <TextField 
                    id="input-name" 
                    required
                    type={'text'}
                    label={"Название"}
                    value={newBotsGroup.name}
                    size="small"
                    variant="standard"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleEdit<'name'>({key: 'name', value: event.target.value})} 
                />
            </TableCell>
            <TableCell key={'note'} align={'left'}>
                <TextField 
                    id="input-note" 
                    required
                    type={'text'}
                    label={"Заметка"}
                    value={newBotsGroup.note}
                    size="small"
                    variant="standard"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleEdit<'note'>({key: 'note', value: event.target.value})} 
                />
            </TableCell>
            <TableCell key={'referral-code'} align={'left'}>
                <TextField 
                    id="input-referral-code" 
                    required
                    type={'text'}
                    label={"Реф. код"}
                    value={newBotsGroup.referral_code}
                    size="small"
                    variant="standard"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleEdit<'referral_code'>({key: 'referral_code', value: event.target.value})} 
                />
            </TableCell>

            <TableCell key={'actions'} align={'right'}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    {/* <IconButton color="warning" aria-label="close" onClick={() => dispatch(setCreateNewBotIsOpen(false))}>
                        <CloseIcon />
                    </IconButton> */}
                    <IconButton color="success" aria-label="save" onClick={handleSaveNewBot}>
                        <DoneIcon />
                    </IconButton>
                </Grid>
            </TableCell>
        </TableRow>
    </>);
};

export default CreateNewBotsGroup;
