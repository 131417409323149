import { Button } from '@mui/material';
import React, { FC } from 'react';
import { IUrlButtonData } from '.';

interface IUrlButtonProps {
    data: IUrlButtonData;
}

const UrlButton: FC<IUrlButtonProps> = (props) => {
    return <Button href={props.data.url} variant='outlined' fullWidth>{props.data.text}</Button>
}

export default UrlButton;