import { Add, Clear } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { IntervalPeriod, IPostSchedule, IPostScheduleInterval, IPostScheduleSchedule, ScheduleType, WeekDay } from '../../../interface/interface';
import IntervalSchedule from './intervalSchedule';
import ScheduleConstructor from './ScheduleConstructor';

interface IScheduleProps {
    schedule: IPostSchedule;
    onChange: (postSchedule: IPostSchedule) => void;
}

const emptySchedule: IPostScheduleSchedule = {
    type: ScheduleType.SCHEDULE,
    weekDays: [],
    hour: [],
    minute: [0],
}
const emptyInterval: IPostScheduleInterval = {
    type: ScheduleType.INTERVAL,
    period: IntervalPeriod.MINUTE,
    value: 5,
}

// const checkIsLastScheduleFilled = (schedule: IPostSchedule[]): boolean => {
//     const last = schedule.at(-1);
//     if (!last) {
//         return false;
//     }
//     if (last?.type === ScheduleType.INTERVAL) {
//         return schedule.every((val, i, arr) => val.type === ScheduleType.INTERVAL && i < arr.length - 1
//             ? val.period !== last.period || val.value !== last.value
//             : true
//         );
//     } else {
//         return !!last.hour.length || !!last.minute.length || !!last?.weekDays.length;
//     }
// }

/** yyyy-MM-ddThh:mm */
const formatDate = (date: Date): string => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
}
const parseDate = (string: string): Date => {
    const [year, month, day, hour, minute] = string.split(/[-T:]/);
    const date = new Date();
    date.setFullYear(+year);
    date.setMonth((+month) - 1);
    date.setDate(+day);
    date.setHours(+hour);
    date.setMinutes(+minute);
    return date;
}

const Schedule: FC<IScheduleProps> = (props) => {
    const [needDateStart, setNeedDateStart] = useState(!!props.schedule.dateStart);
    const [needDateEnd, setNeedDateEnd] = useState(!!props.schedule.dateEnd);
    // const [isLastScheduleFilled, setIsLastScheduleFilled] = useState(checkIsLastScheduleFilled(props.schedule));

    // useEffect(() => {
    //     setIsLastScheduleFilled(checkIsLastScheduleFilled(props.schedule));
    // }, [props.schedule])
    useEffect(() => {
        setNeedDateStart(!!props.schedule.dateStart);
        setNeedDateEnd(!!props.schedule.dateEnd);
    }, [props.schedule])

    const updateSchedule = (schedule: IPostSchedule['schedules'][0], index: number) => {
        const schedules = [...props.schedule.schedules];
        schedules[index] = {...schedule}
        console.log("updateSchedule", schedules[index]);
        
        props.onChange({...props.schedule, schedules});
    }
    const removeSchedule = (index: number) => {
        const schedules = [...props.schedule.schedules];
        schedules.splice(index, 1);
        props.onChange({...props.schedule, schedules});
    }
    const addNewSchedule = () => {
        const schedules = [...props.schedule.schedules];
        schedules.push(emptySchedule);
        props.onChange({...props.schedule, schedules});
    }
    const addNewInterval = () => {
        const schedules = [...props.schedule.schedules];
        schedules.push(emptyInterval);
        props.onChange({...props.schedule, schedules});
    }

    return (<>
        <Stack my={2} spacing={3} >
            {props.schedule.schedules.map((val, i) => {
                return (<Stack direction={'row'} sx={{width: '100%'}} spacing={0.25} key={i} >
                    <Stack spacing={1} sx={{flexGrow: 1}}>
                        {val.type === ScheduleType.INTERVAL 
                            ? <IntervalSchedule 
                                    schedule={val}
                                    onChange={(schedule) => updateSchedule(schedule, i)}
                                    key={i}
                                    index={i}
                                />
                            : <ScheduleConstructor schedule={val} onChange={(schedule) => updateSchedule(schedule, i)} key={i} index={i} />
                        }
                    </Stack>
                    <Button onClick={() => removeSchedule(i)} variant='outlined' color='error' ><Clear/></Button>
                </Stack>)
            })}
            <Grid container>
                {/* <Grid item xs={12} sm={6}> */}
                    <Button fullWidth onClick={addNewSchedule}><Add/> Расписание</Button>
                {/* </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                    <Button fullWidth onClick={addNewInterval}><Add/> Интервал</Button>
                </Grid> */}
            </Grid>
            <Grid container rowSpacing={1} >
                <Grid item xs={12} md={6} >
                    <FormControlLabel 
                        control={
                            <Checkbox checked={needDateStart ?? false} onChange={(e, val) => {setNeedDateStart(val); props.onChange({...props.schedule, dateStart: val ? new Date() : undefined})}} />
                        } 
                        label="Задать дату начала" 
                    />
                    {needDateStart && <TextField
                        id={`start-date`}
                        label="Дата начала"
                        type="datetime-local"
                        sx={{ width: '100%' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => props.onChange({...props.schedule, dateStart: parseDate(e.target.value)})}
                        value={formatDate(new Date(props.schedule.dateStart?.toString() ?? Date.now()))}
                    />}
                </Grid>
                <Grid item xs={12} md={6} >
                    <FormControlLabel 
                        control={
                            <Checkbox checked={needDateEnd ?? false} onChange={(e, val) => {setNeedDateEnd(val); props.onChange({...props.schedule, dateEnd: val ? new Date() : undefined})}} />
                        } 
                        label="Задать дату окончания" 
                    />
                    {needDateEnd && <TextField
                        id={`end-date`}
                        label="Дата окончания"
                        type="datetime-local"
                        sx={{ width: '100%' }}
                        InputLabelProps={{
                            shrink: true,    
                        }}
                        onChange={(e) => props.onChange({...props.schedule, dateEnd: parseDate(e.target.value)})}
                        value={formatDate(new Date(props.schedule.dateEnd?.toString() ?? Date.now()))}
                    />}
                </Grid>
            </Grid>
        </Stack>
    </>);
};

export default Schedule;
