import { Alert, Box, Grid, IconButton, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, {FC, useState} from 'react';
import { Edit as EditIcon, Done as DoneIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import CreateNewBot from './createNewBot';
import { IBot } from '../../interface/interface';
import { useDeleteBotMutation, useGetBotsGroupQuery } from '../../services/bots/botsGroupApi';

interface IBotsTableProps {
    groupId: number;
}

interface Column {
    id: 'id' | 'username';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (...value: any) => React.ReactNode;
    onEdit?: (...value: any) => React.ReactNode,
}

const columns: readonly Column[] = [
    { id: 'id', label: 'ID', minWidth: 50 },
    {
        id: 'username',
        label: 'Юзернейм',
        minWidth: 270,
        format: (value: IBot['username']) => <Link href={`https://t.me/${value}`} target={'_blank'} >@{value}</Link>,
        // onEdit: (val: IEditProps) => <UserbotSelector defaultValue={val.value} proxyId={val.proxyId} />,
    },
];

const BotsTable: FC<IBotsTableProps> = (props) => {
    const {isLoading, isError, data} = useGetBotsGroupQuery(props.groupId, {
        refetchOnFocus: true
    });
    const [deleteBot] = useDeleteBotMutation();
    const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
    const [editing, setEditing] = useState(false);

    const handleToggleEdit = () => {
        setEditing(!editing);
    };

    return (<>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant='h4' m={2}>Боты</Typography>
            {isLoading && <LinearProgress />}
            {isError && <Alert severity="error">Произошла ошибка!</Alert>}
            {!isError && !isLoading && <>
                <TableContainer sx={{ maxHeight: '75vh' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!data?.botsToGroup.length && data.botsToGroup.map(({bot}) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={bot.id}>
                                        {columns.map((column) => {
                                            const value = bot[column.id];
                                            const val = column.format ? column.format(value) : value?.toString();
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {editing && !!column.onEdit
                                                        ? column.onEdit({value: val, proxyId: bot.id})
                                                        : val
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                        {editing && <TableCell key={'delete'} align={'center'}>
                                            <IconButton color="primary" aria-label="delete" onClick={() => deleteBot({groupId: props.groupId, botId: bot.id})}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </TableCell>}
                                    </TableRow>
                                );
                            })}
                            {createNewIsOpen && <CreateNewBot groupId={props.groupId}/>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    {!createNewIsOpen && <Box m={2}>
                        <IconButton color="primary" aria-label="edit" onClick={() => setCreateNewIsOpen(true)}>
                            <AddIcon/>
                        </IconButton>
                    </Box>}
                    <Box m={2}>
                        <IconButton color="primary" aria-label="add-proxy" onClick={handleToggleEdit}>
                            {!!editing ? <DoneIcon /> : <EditIcon />}
                        </IconButton>
                    </Box>
                </Grid>
            </>}
        </Paper>
    </>);
};

export default BotsTable;
